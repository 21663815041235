export default {
  'original_password': 'Mot de passe original',
  'new_password': 'Nouveau mot de passe',
  'enter_the_original_password': 'Entrez le mot de passe original',
  'enter_the_new_password_(enter_8_or_more_characters)': 'Entrez le nouveau mot de passe (entrez 8 caractères ou plus)',
  'confirm_password': 'Confirmez le mot de passe',
  're_enter_the_new_password': 'Re-entrez le nouveau mot de passe',
  'confirm_modification': 'Confirmez la modification',
  'enter_new_password_enter_6_digit_number': 'Entrez le nouveau mot de passe (entrez un nombre à 6 chiffres)',
  'recharge_amount': 'Montant de recharge',
  'reason_for_rejection': 'Raison du rejet',
  'time': 'heure',
  'in_review': 'En revue',
  'completed': 'Terminé',
  'rejected': 'Rejeté',
  'current_browser_does_not_support_copy': 'Le navigateur actuel ne supporte pas la copie',
  'copy_successful': 'Copie réussie',
  'home': 'Accueil',
  'liquidity_pool': 'Pool de liquidité',
  'me': 'Moi',
  'recharge': 'Recharger',
  'withdraw': 'Retirer',
  'buy_second_contract': 'Acheter un deuxième contrat',
  'settle_second_contract': 'Régler le deuxième contrat',
  'buy_lever': 'Acheter un levier',
  'settle_lever': 'Régler le levier',
  'stake_lp': 'Déposer LP',
  'earnings_lp': 'Gains LP',
  'settlement_lp': 'Règlement LP',
  'admin': 'Admin',
  'withdraw_amount': 'Montant à retirer',
  'withdrawal_address': 'Adresse de retrait',
  'rejection_reason': 'Raison du rejet',
  'under_review': 'En cours de revue',
  'invitation_code': 'Code d\'invitation',
  'balance': 'Solde',
  'today': 'Aujourd\'hui',
  'deposit': 'Dépôt',
  'real_name_authentication': 'Authentification nom réel',
  'billing_history': 'Historique de facturation',
  'second_contract_order': 'Commande de deuxième contrat',
  'liquidity_pool_order': 'Commande de pool de liquidité',
  'my_team': 'Mon équipe',
  'change_password': 'Changer le mot de passe',
  'logout': 'Déconnexion',
  'cancel': 'Annuler',
  'document_type': 'Type de document',
  'real_name': 'Nom réel',
  'please_enter_real_name': 'Veuillez entrer le nom réel',
  'please_enter_document_number': 'Veuillez entrer le numéro de document',
  'document_number': 'Numéro de document',
  'document_photo': 'Photo de document',
  'front': 'Avant',
  'back': 'Arrière',
  'submit': 'Soumettre',
  'certification_passed': 'Certification réussie',
  'identity_card': 'Carte d\'identité',
  'passport': 'Passeport',
  'login_password': 'Mot de passe de connexion',
  'transaction_password': 'Mot de passe de transaction',
  'low': 'Basse',
  'high': 'Haute',
  'open': 'Ouvert',
  'close': 'Fermé',
  'buy_rise': 'Acheter à la hausse',
  'buy_fall': 'Acheter à la baisse',
  'return_rate': 'Taux de retour',
  'capital': 'Capital',
  'is_leverage': 'Est-ce un levier',
  'leverage_ratio': 'Ratio de levier',
  'amount': 'Montant',
  'available_amount': 'Montant disponible',
  'expected_return': 'Retour attendu',
  'transaction_fee': 'Frais de transaction',
  'confirmation': 'Confirmation',
  'price': 'Prix',
  'direction': 'Direction',
  'rise': 'Hausse',
  'fall': 'Baisse',
  'enter_payment_password': 'Entrez le mot de passe de paiement',
  'in_progress': 'En cours',
  'final_price': 'Prix final',
  'leverage': 'Levier',
  'handling_fee': 'Frais de traitement',
  'expected_profit_loss': 'Gain/Perte attendu',
  'creation_time': 'Heure de création',
  'no_more': 'Plus de',
  'has_ended': 'Est terminé',
  'actual_profit_and_loss': 'Bénéfice et perte réels',
  'end_time': 'Heure de fin',
  'billing_record': 'Enregistrement de facturation',
  'currency_pair': 'Paire de devises',
  'latest_price': 'Dernier prix',
  'price_change_percentage': 'Pourcentage de changement de prix',
  'staking_liquidity_pool': 'Staking de pool de liquidité',
  'participate_in_staking': 'Participer à la staking',
  'current_total_staking': 'Total actuel de la staking',
  'cumulative_rewards': 'Récompenses cumulées',
  'add_liquidity': 'Ajouter de la liquidité',
  'daily_yield_rate': 'Taux de rendement quotidien',
  'staking_limit': 'Limite de staking',
  'day': 'Jour',
  'staking_time': 'Heure de staking',
  'input_staking_amount': 'Montant de staking à saisir',
  'staking_amount': 'Montant de staking',
  'total_return_rate': 'Taux de retour total',
  'confirm': 'Confirmer',
  'liquidity_pool_staking_order': 'Commande de staking de pool de liquidité',
  'daily_profit': 'Profit quotidien',
  'due_date': 'Date d\'échéance',
  'order_id': 'Numéro de commande',
  'expiration_time': 'Heure d\'expiration',
  'cancel_order': 'Annuler la commande',
  'confirm_cancellation': 'Confirmer l\'annulation',
  'penalty_rate': 'Taux de pénalité',
  'expected_arrival_amount': 'Montant attendu d\'arrivée',
  'login_account': 'Compte de connexion',
  'input_email_account': 'Entrer le compte email',
  'email': 'Email',
  'password': 'Mot de passe',
  'input_login_password': 'Entrer le mot de passe de connexion',
  'login': 'Se connecter',
  'register_now': 'S\'inscrire maintenant',
  'no_account_yet': 'Pas encore de compte',
  'account': 'Compte',
  'invitation_time': 'Heure de l\'invitation',
  'level': 'Niveau',
  'please_select_withdrawal_currency': 'Veuillez sélectionner la devise de retrait',
  'withdrawal_amount': 'Montant de retrait',
  'please_enter_withdrawal_amount': 'Veuillez entrer le montant de retrait',
  'please_enter_withdrawal_address': 'Veuillez entrer l\'adresse de retrait',
  'please_enter_transaction_password': 'Veuillez entrer le mot de passe de transaction',
  'estimated_actual_arrival': 'Arrivée estimée',
  'please_select_recharge_currency': 'Veuillez sélectionner la devise de recharge',
  'recharge_address': 'Adresse de recharge',
  'please_enter_recharge_amount': 'Veuillez saisir le montant de recharge',
  'please_enter_recharge_hash': 'Veuillez saisir le hash de recharge',
  'recharge_hash': 'Hash de recharge',
  'upload_recharge_proof_payment_screenshot': 'Télécharger la preuve de recharge (capture d\'écran de paiement)',
  'please_authenticate_first': 'Veuillez vous authentifier d\'abord',
  'create_account': 'Créer un compte',
  'verification_code': 'Code de vérification',
  'enter_verification_code': 'Entrez le code de vérification',
  'get_verification_code': 'Obtenir le code de vérification',
  'funds_password': 'Mot de passe des fonds',
  'register': 'S\'inscrire',
  'set_funds_password_6_digit_number': 'Définir le mot de passe des fonds (6 chiffres)',
  'set_login_password_input_8_plus_characters': 'Définir le mot de passe d\'accès (entrez 8+ caractères)',
  'please_enter_email': 'Veuillez saisir votre adresse électronique',
  "announcement": "Annonce",
  "announcement_details": "Détails de l'annonce",
  "times": "Fois",
  "the_purchase_period_has_ended_please_make_a_new_selection": "La période d'achat est terminée, veuillez faire une nouvelle sélection",
};
