export default {
  'original_password': 'Contraseña original',
  'new_password': 'Nueva contraseña',
  'enter_the_original_password': 'Introduzca la contraseña original',
  'enter_the_new_password_(enter_8_or_more_characters)': 'Introduzca la nueva contraseña (ingrese 8 o más caracteres)',
  'confirm_password': 'Confirmar contraseña',
  're_enter_the_new_password': 'Vuelva a introducir la nueva contraseña',
  'confirm_modification': 'Confirmar modificación',
  'enter_new_password_enter_6_digit_number': 'Introduzca la nueva contraseña (ingrese un número de 6 dígitos)',
  'recharge_amount': 'Cantidad de recarga',
  'reason_for_rejection': 'Motivo del rechazo',
  'time': 'hora',
  'in_review': 'En revisión',
  'completed': 'Completado',
  'rejected': 'Rechazado',
  'current_browser_does_not_support_copy': 'El navegador actual no admite la copia',
  'copy_successful': 'Copia exitosa',
  'home': 'Inicio',
  'liquidity_pool': 'Piscina de liquidez',
  'me': 'Mi cuenta',
  'recharge': 'Recargar',
  'withdraw': 'Retirar',
  'buy_second_contract': 'Comprar segundo contrato',
  'settle_second_contract': 'Solucionar segundo contrato',
  'buy_lever': 'Comprar palanca',
  'settle_lever': 'Solucionar palanca',
  'stake_lp': 'Invertir LP',
  'earnings_lp': 'Ganancias LP',
  'settlement_lp': 'Settlement LP',
  'admin': 'Administrador',
  'withdraw_amount': 'Cantidad a retirar',
  'withdrawal_address': 'Dirección de retiro',
  'rejection_reason': 'Motivo del rechazo',
  'under_review': 'En revisión',
  'invitation_code': 'Código de invitación',
  'balance': 'Balance',
  'today': 'Hoy',
  'deposit': 'Depósito',
  'real_name_authentication': 'Autenticación de nombre real',
  'billing_history': 'Historial de facturas',
  'second_contract_order': 'Pedido de segundo contrato',
  'liquidity_pool_order': 'Pedido de pool de liquidez',
  'my_team': 'Mi equipo',
  'change_password': 'Cambiar contraseña',
  'logout': 'Cerrar sesión',
  'cancel': 'Cancelar',
  'document_type': 'Tipo de documento',
  'real_name': 'Nombre real',
  'please_enter_real_name': 'Por favor, introduzca su nombre real',
  'please_enter_document_number': 'Por favor, introduzca el número de documento',
  'document_number': 'Número de documento',
  'document_photo': 'Foto de documento',
  'front': 'Frontal',
  'back': 'Trasera',
  'submit': 'Enviar',
  'certification_passed': 'Certificación aprobada',
  'identity_card': 'Tarjeta de identidad',
  'passport': 'Pasaporte',
  'login_password': 'Contraseña de inicio de sesión',
  'transaction_password': 'Contraseña de transacción',
  'low': 'Bajo',
  'high': 'Alto',
  'open': 'Abrir',
  'close': 'Cerrar',
  'buy_rise': 'Comprar alcista',
  'buy_fall': 'Comprar bajista',
  'return_rate': 'Tasa de retorno',
  'capital': 'Capital',
  'is_leverage': 'Es palanca',
  'leverage_ratio': 'Ratio de palanca',
  'amount': 'Cantidad',
  'available_amount': 'Cantidad disponible',
  'expected_return': 'Retorno esperado',
  'transaction_fee': 'Comisión de transacción',
  'confirmation': 'Confirmación',
  'price': 'Precio',
  'direction': 'Dirección',
  'rise': 'Subida',
  'fall': 'Bajada',
  'enter_payment_password': 'Introduzca la contraseña de pago',
  'in_progress': 'En progreso',
  'final_price': 'Precio final',
  'leverage': 'Palanca',
  'handling_fee': 'Tarifa de manejo',
  'expected_profit_loss': 'Ganancia/Pérdida esperada',
  'creation_time': 'Hora de creación',
  'no_more': 'No más',
  'has_ended': 'Ha terminado',
  'actual_profit_and_loss': 'Ganancia y pérdida actual',
  'end_time': 'Hora de finalización',
  'billing_record': 'Registro de facturación',
  'currency_pair': 'Par de monedas',
  'latest_price': 'Último precio',
  'price_change_percentage': 'Porcentaje de cambio de precio',
  'staking_liquidity_pool': 'Staking de pool de liquidez',
  'participate_in_staking': 'Participar en staking',
  'current_total_staking': 'Total actual de staking',
  'cumulative_rewards': 'Recompensas acumuladas',
  'add_liquidity': 'Agregar liquidez',
  'daily_yield_rate': 'Tasa de rendimiento diaria',
  'staking_limit': 'Límite de staking',
  'day': 'Día',
  'staking_time': 'Hora de staking',
  'input_staking_amount': 'Cantidad de staking a ingresar',
  'staking_amount': 'Cantidad de staking',
  'total_return_rate': 'Tasa de retorno total',
  'confirm': 'Confirmar',
  'liquidity_pool_staking_order': 'Pedido de staking de pool de liquidez',
  'daily_profit': 'Beneficio diario',
  'due_date': 'Fecha de vencimiento',
  'order_id': 'ID de pedido',
  'expiration_time': 'Hora de expiración',
  'cancel_order': 'Cancelar pedido',
  'confirm_cancellation': 'Confirmar cancelación',
  'penalty_rate': 'Tasa de multa',
  'expected_arrival_amount': 'Monto de llegada esperado',
  'login_account': 'Cuenta de inicio de sesión',
  'input_email_account': 'Introduzca la cuenta de correo electrónico',
  'email': 'Correo electrónico',
  'password': 'Contraseña',
  'input_login_password': 'Introduzca la contraseña de inicio de sesión',
  'login': 'Iniciar sesión',
  'register_now': 'Regístrese ahora',
  'no_account_yet': 'No tiene aún un cuenta',
  'account': 'Cuenta',
  'invitation_time': 'Hora de la invitación',
  'level': 'Nivel',
  'please_select_withdrawal_currency': 'Por favor, seleccione la moneda de retiro',
  'withdrawal_amount': 'Cantidad de retiro',
  'please_enter_withdrawal_amount': 'Por favor, introduzca la cantidad de retiro',
  'please_enter_withdrawal_address': 'Por favor, introduzca la dirección de retiro',
  'please_enter_transaction_password': 'Por favor, introduzca la contraseña de transacción',
  'estimated_actual_arrival': 'Llegada estimada',
  'please_select_recharge_currency': 'Por favor, seleccione la moneda de recarga',
  'recharge_address': 'Dirección de recarga',
  'please_enter_recharge_amount': 'Por favor, introduzca la cantidad de recarga',
  'please_enter_recharge_hash': 'Por favor, introduzca el hash de recarga',
  'recharge_hash': 'Hash de recarga',
  'upload_recharge_proof_payment_screenshot': 'Subir la prueba de recarga (captura de pantalla de pago)',
  'please_authenticate_first': 'Por favor, autentíquese primero',
  'create_account': 'Crear una cuenta',
  'verification_code': 'Código de verificación',
  'enter_verification_code': 'Introduzca el código de verificación',
  'get_verification_code': 'Obtener el código de verificación',
  'funds_password': 'Contraseña de fondos',
  'register': 'Registrar',
  'set_funds_password_6_digit_number': 'Establecer la contraseña de fondos (6 dígitos)',
  'set_login_password_input_8_plus_characters': 'Establecer la contraseña de inicio de sesión (introduzca 8 o más caracteres)',
  'please_enter_email': 'Por favor, introduzca su correo electrónico',
  "announcement": "Anuncio",
  "announcement_details": "Detalles del anuncio",
  "times": "Veces",
  "the_purchase_period_has_ended_please_make_a_new_selection": "El período de compra ha terminado, por favor haga una nueva selección",
};
