import { createI18n } from "vue-i18n";
import enUS from "./en-US/index";
import esEs from "./es-ES/index";
import frFR from "./fr-FR/index";
import itIT from "./it-IT/index";

const messages = {
  en_US: {
    ...enUS,
  },
  es_ES: {
    ...esEs,
  },
  fr_FR: {
    ...frFR,
  },
  it_IT: {
    ...itIT,
  },
};

const i18n = createI18n({
  locale: window.localStorage.getItem('lang') ?? 'en_US',
  legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  globalInjection: true, // 全局注册$t方法
  messages,
});

export default i18n;
