import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'login',
    },
  },
  {
    name: 'noticeDetails',
    path: '/noticeDetails',
    component: () => import('./view/noticeDetails.vue'),
  },
  {
    name: 'notices',
    path: '/notices',
    component: () => import('./view/notices.vue'),
  },
  {
    name: 'team',
    path: '/team',
    component: () => import('./view/team.vue'),
  },
  {
    name: 'changePwd',
    path: '/changePwd',
    component: () => import('./view/changePwd.vue'),
  },
  {
    name: 'withdrawal',
    path: '/withdrawal',
    component: () => import('./view/withdrawal.vue'),
  },
  {
    name: 'historyBill',
    path: '/historyBill',
    component: () => import('./view/historyBill.vue'),
  },
  {
    name: 'liquidityOrder',
    path: '/liquidityOrder',
    component: () => import('./view/liquidityOrder.vue'),
  },
  {
    name: 'contractOrder',
    path: '/contractOrder',
    component: () => import('./view/contractOrder.vue'),
  },
  {
    name: 'recharge',
    path: '/recharge',
    component: () => import('./view/recharge.vue'),
  },
  {
    name: 'auth',
    path: '/auth',
    component: () => import('./view/auth.vue'),
  },
  {
    name: 'liquidity_pool',
    path: '/liquidity_pool',
    component: () => import('./view/liquidity_pool.vue'),
  },
  {
    name: 'contract',
    path: '/contract/:id',
    component: () => import('./view/contract.vue'),
    props: true,
  },
  {
    name: 'me',
    path: '/me',
    component: () => import('./view/me.vue'),
  },
  {
    name: 'liquidity',
    path: '/liquidity',
    component: () => import('./view/liquidity.vue'),
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home.vue'),
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login.vue'),
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('./view/register.vue'),
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
